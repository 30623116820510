// ** React Imports
import React, { useState } from 'react';

// ** MUI Imports
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import List from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { ExpandMore } from '@mui/icons-material';

// ** Third Party Imports
import { usePopper } from 'react-popper';

// ** Custom Components Imports
import NavItems from './navItems';
import { Link } from 'gatsby';

// ** Styled Components
const ListItem = styled(MuiListItem)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    background: 'rgba(0, 168, 124, 0.3)',
    textDecoration: 'none',
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  color: '#fff',
  fontWeight: 400,
  fontSize: '20px',
}));

const NavigationMenu = styled(Paper)(({ theme }) => ({
  overflowY: 'auto',
  cursor: 'pointer',
  padding: theme.spacing(2, 0),
  background: 'linear-gradient(347deg, #000 -0.91%, rgba(0, 0, 0, 0.80) 96.15%)',
  width: 260,
  '&::-webkit-scrollbar': {
    width: 6,
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: 20,
    background: 'red',
  },
  '&::-webkit-scrollbar-track': {
    borderRadius: 20,
    background: 'transparent',
  },
  '& .MuiList-root': {
    paddingTop: 0,
    paddingBottom: 0,
  },
  '& .menu-group.Mui-selected': {
    borderRadius: 0,
    backgroundColor: theme.palette.action.hover,
  },
}));

const NavGroup = (props) => {
  // ** Props
  const { item, hasParent } = props;

  const popperOffsetHorizontal = 16;
  const popperPlacement = 'bottom-start';
  const popperPlacementSubMenu = 'right-start';

  // ** States
  const [menuOpen, setMenuOpen] = useState(false);
  const [popperElement, setPopperElement] = useState(null);
  const [referenceElement, setReferenceElement] = useState(null);

  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: hasParent ? popperPlacementSubMenu : popperPlacement,
    modifiers: [
      {
        enabled: true,
        name: 'offset',
        options: {
          offset: hasParent ? [-8, 10] : [popperOffsetHorizontal, -10],
        },
      },
      {
        enabled: true,
        name: 'flip',
      },
    ],
  });

  const handleGroupOpen = (event) => {
    setMenuOpen(true);
    update ? update() : null;
  };

  const handleGroupClose = () => {
    setMenuOpen(false);
  };

  return (
    <Box {...{ onMouseLeave: handleGroupClose }}>
      <List component="div" sx={{ py: 2.75 }}>
        <ListItem
          component={Link}
          to={item.route?.slug?.current === undefined ? '/' : `/${item.route?.slug?.current}`}
          aria-haspopup="true"
          {...{ onMouseEnter: handleGroupOpen }}
        >
          <Box
            sx={{
              gap: 2,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            ref={setReferenceElement}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Text>{item.title}</Text>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ExpandMore style={{ color: '#FFFFFF' }} />
            </Box>
          </Box>
        </ListItem>
        <Fade {...{ in: menuOpen, timeout: { exit: 300, enter: 400 } }}>
          <Box
            style={styles.popper}
            ref={setPopperElement}
            {...attributes.popper}
            sx={{
              zIndex: 100,
              ...{ display: 'block' },
              pl: 1.25,
              pr: 1.25,
              ...(hasParent ? { position: 'fixed !important' } : { pt: 5.5 }),
            }}
          >
            <NavigationMenu
              sx={{
                ...(hasParent
                  ? { overflowX: 'visible', maxHeight: 'calc(100vh - 21rem)' }
                  : { maxHeight: 'calc(100vh - 13rem)' }),
                ...{ boxShadow: 4 },
              }}
            >
              <NavItems {...props} hasParent horizontalNavItems={item.subItems} />
            </NavigationMenu>
          </Box>
        </Fade>
      </List>
    </Box>
  );
};

export default NavGroup;
