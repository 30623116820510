import React from 'react';
import NavLink from './navLink';
import NavGroup from './navGroup';

const resolveComponent = (item) => {
  if (item.subItems?.length > 0) return NavGroup;

  return NavLink;
};

const NavItems = (props) => {
  const RenderMenuItems = props.horizontalNavItems?.map((item, index) => {
    const TagName = resolveComponent(item);

    return <TagName {...props} key={index} item={item} />;
  });

  return <>{RenderMenuItems}</>;
};

export default NavItems;
