// extracted by mini-css-extract-plugin
export var base = "buttons-module--base--OJF1t";
export var blockQuote = "buttons-module--blockQuote--UnTiu";
export var button = "buttons-module--button--Di5kN";
export var buttonOutline = "buttons-module--button-outline--S9SIb";
export var calloutButton = "buttons-module--calloutButton--8HFu9";
export var disabled = "buttons-module--disabled--I+Xtu";
export var large = "buttons-module--large--XEemV";
export var micro = "buttons-module--micro--GnhV5";
export var paragraph = "buttons-module--paragraph--ZTAk7";
export var responsiveTitle1 = "buttons-module--responsiveTitle1--YNyTi";
export var responsiveTitle2 = "buttons-module--responsiveTitle2--fnQZy";
export var responsiveTitle3 = "buttons-module--responsiveTitle3--5fXL3";
export var responsiveTitle4 = "buttons-module--responsiveTitle4---RJ61";
export var responsiveTitle5 = "buttons-module--responsiveTitle5--3S506";
export var responsiveTitleHuge = "buttons-module--responsiveTitleHuge--k2O++";
export var small = "buttons-module--small--OYRJ1";
export var title1 = "buttons-module--title1--ct5QD";
export var title2 = "buttons-module--title2--ZXgPB";
export var title3 = "buttons-module--title3--BSNfK";