import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import clientConfig from '../../client-config';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import { root, removeFullWidthOnMobileCSS, removeBottomMarginCSS } from './figure.module.css';
import { cn } from '../lib/helpers';

const Figure = ({ node }) => {
  if (!node.image) {
    return null;
  }
  const { removeFullWidthOnMobile, removeBottomMargin } = node;
  const gatsbyImageData = getGatsbyImageData(node.image, { maxWidth: 675 }, clientConfig.sanity);
  return (
    node.image && (
      <figure
        className={cn(
          root,
          removeFullWidthOnMobile && removeFullWidthOnMobileCSS,
          removeBottomMargin && removeBottomMarginCSS
        )}
      >
        <GatsbyImage image={gatsbyImageData} alt={node.alt} />
      </figure>
    )
  );
};

export default Figure;
