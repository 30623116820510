import React, { useContext } from 'react';
import { root } from './loader.module.css';
import { SiteContext } from '../containers/layout';
import { Keyshape } from 'react-keyshape';
import LoaderSVGFull from '../images/svg/preloader-logo-js-4.svg';
import LoaderSVGLogoOnly from '../images/svg/preloader-logo-js-2.svg';
import { Tween } from 'react-gsap';

const Loader = (props) => {
  const { preloaderAnimation } = props;
  const { setLoaderDone } = useContext(SiteContext);
  return (
    <Tween
      from={{ opacity: 1 }}
      to={{ autoAlpha: 0 }}
      duration={0.5}
      delay={preloaderAnimation === 'short' ? 3 : 8}
      onComplete={() => setLoaderDone(true)}
    >
      <div className={root}>
        <Keyshape svg={preloaderAnimation === 'short' ? LoaderSVGLogoOnly : LoaderSVGFull} />
      </div>
    </Tween>
  );
};

export default Loader;
