module.exports = {
  sanity: {
    projectId: process.env.GATSBY_SANITY_PROJECT_ID || 'l7780ks7',
    dataset: process.env.GATSBY_SANITY_DATASET || 'production',
  },
  gatsby: {
    siteTitle: 'Ultima Genomics, Inc.',
    siteDescription: 'Ultima Genomics, Inc.',
    siteBaseUrl: 'ultimagenomics.com',
    siteUrl: `https://www.ultimagenomics.com`,
    // siteUrl: 'https://ultimagen.netlify.app', // change this to above siteUrl when we go live
    userTwitter: '@ultimagenomics',
    author: 'Ultima Genomics, Inc.',
    authorUrl: 'https://www.ultimagenomics.com',
    shortTitle: 'Ultima Genomics, Inc.',
  },
};
