import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

import myConfiguredSanityClient from '../../client-config';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(myConfiguredSanityClient.sanity);

export function urlFor(source) {
  return builder.image(source);
}

export const hasHeroSection = (content) => content.find((ct) => ct.__typename === 'SanitySectionHero');

export const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

const formatText = (content) => {
  let textArray = [];
  if (content?.body) {
    content.body.forEach(({ children }) => {
      if (children) {
        children.forEach(({ text }) => {
          if (text) {
            textArray.push(text);
          }
        });
      }
    });
  } else if (Array.isArray(content)) {
    if (content.length > 0) {
      content.forEach((inner) => {
        if (inner.body) {
          inner.body.forEach(({ children }) => {
            if (children) {
              children.forEach(({ text }) => {
                if (text) {
                  textArray.push(text);
                }
              });
            }
          });
        }
      });
    }
  }

  return textArray;
};

export const handlePageMap = (props) => {
  const { content, slug } = props;
  const tempReturn = {
    ...props,
    body: formatText(content),
    slug: slug.current,
  };
  delete tempReturn.content;
  return tempReturn;
};

export const waitForGlobal = function (key, callback) {
  if (window[key]) {
    callback();
  } else {
    setTimeout(function () {
      waitForGlobal(key, callback);
    }, 100);
  }
};
