import BaseBlockContent from '@sanity/block-content-to-react';
import React, { useContext } from 'react';
import clientConfig from '../../client-config';
import serializers from './serializers';
import { SiteContext } from '../containers/layout';

const BlockContent = ({ blocks }) => {
  const { setGatedModalOpen, setGatedModalLink } = useContext(SiteContext);
  return (
    <BaseBlockContent
      blocks={blocks}
      serializers={serializers(setGatedModalOpen, setGatedModalLink)}
      {...clientConfig.sanity}
    />
  );
};

export default BlockContent;
