import { graphql, Link, StaticQuery } from 'gatsby';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { useLocation } from '@gatsbyjs/reach-router';

import {
  root,
  wrapper,
  branding,
  socialIcons,
  container,
  footerCopyright,
  footerCopyrightWrapper,
  footerCopyrightLinks,
  footerMenu,
} from './footer.module.css';
import clientConfig from '../../../client-config';

const Footer = () => {
  const location = useLocation();
  return (
    // eslint-disable-next-line react/jsx-no-undef
    <StaticQuery
      query={graphql`
        {
          footerLegalLinks: sanityMenu(slug: { current: { eq: "footer-legal-links" } }) {
            title
            links {
              _key
              title
              link
              buttonLink
              route {
                ... on SanityPage {
                  slug {
                    current
                  }
                  title
                }
              }
              hidden
            }
          }
          footerLinks: sanityMenu(slug: { current: { eq: "footer-links" } }) {
            title
            links {
              _key
              title
              link
              buttonLink
              route {
                ... on SanityPage {
                  slug {
                    current
                  }
                  title
                }
              }
              hidden
              embeddedMenu {
                ...EmbeddedMenu
              }
            }
          }
          site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
            socialLinks {
              ... on SanitySocialIcon {
                image {
                  ...Figure
                }
                link
              }
            }
          }
        }
      `}
      render={({
        footerLinks: { links: footerLinksArray },
        footerLegalLinks: { links: footerLegalLinksArray },
        site: { socialLinks },
      }) => {
        // console.log('socialLinks: ', socialLinks);
        return (
          <div className={root}>
            <div className={container}>
              <div className={wrapper}>
                <Link
                  className={branding}
                  to="/"
                  onClick={(event) => {
                    // workaround for animation getting messed up when clicking on logo from home page
                    if (location.pathname === '/') {
                      event.preventDefault();
                      window.scrollTo(0, 0);
                    }
                  }}
                >
                  <StaticImage
                    src="../../images/logo.png"
                    loading="eager"
                    alt="Ultima Genomics Logo"
                    placeholder="none"
                  />
                </Link>
                <div className={footerMenu}>
                  {footerLinksArray.map(({ _key, title, link, route, hidden, buttonLink, embeddedMenu }) => {
                    const menuLink = link || (route?.slug?.current && `/${route.slug.current}`);
                    const updatedHref = menuLink?.slice(-1) === '/' ? menuLink.slice(0, -1) : menuLink;
                    return (
                      <div key={_key}>
                        <Link to={updatedHref}>
                          <span>{title}</span>
                        </Link>
                        {embeddedMenu?.length > 0 && (
                          <div className={`${classes.megaMenuContent} mega-content`}>
                            <div>
                              {embeddedMenu.map((menuContent) => (
                                <ul key={menuContent.title}>
                                  <a className={classes.subMenuTitle}>{menuContent.title}</a>
                                  <ul className={classes.subMenu}>
                                    {menuContent.links.map((menuLink) => {
                                      const subMenuLink =
                                        menuLink.link ||
                                        (menuLink.route?.slug?.current && `/${menuLink.route.slug.current}`);
                                      return (
                                        <li key={menuLink._key} className={classes.menuItem}>
                                          <Link to={subMenuLink}>
                                            <span>{menuLink.title}</span>
                                          </Link>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </ul>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={footerCopyright}>
                <div className={footerCopyrightWrapper}>
                  <div>
                    © {new Date().getFullYear()} {clientConfig.gatsby.siteTitle}&nbsp;
                  </div>
                  <ul className={footerCopyrightLinks}>
                    {footerLegalLinksArray.map(({ _key, title, link, route, hidden, buttonLink, embeddedMenu }) => {
                      const menuLink = link || (route?.slug?.current && `/${route.slug.current}`);
                      const updatedHref = menuLink?.slice(-1) === '/' ? menuLink.slice(0, -1) : menuLink;
                      return (
                        <li key={_key}>
                          <Link to={updatedHref}>
                            <span>{title}</span>
                          </Link>
                          {embeddedMenu?.length > 0 && (
                            <div>
                              {embeddedMenu.map((menuContent) => (
                                <ul key={menuContent.title}>
                                  <a className={classes.subMenuTitle}>{menuContent.title}</a>
                                  <ul className={classes.subMenu}>
                                    {menuContent.links.map((menuLink) => {
                                      const subMenuLink =
                                        menuLink.link ||
                                        (menuLink.route?.slug?.current && `/${menuLink.route.slug.current}`);
                                      return (
                                        <li key={menuLink._key} className={classes.menuItem}>
                                          <Link to={subMenuLink}>
                                            <span>{menuLink.title}</span>
                                          </Link>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </ul>
                              ))}
                            </div>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className={socialIcons}>
                  {socialLinks.map((socialLink) => {
                    return (
                      <a key={socialLink.link} target="_blank" href={socialLink.link}>
                        <GatsbyImage
                          image={socialLink.image?.image?.asset?.gatsbyImageData}
                          alt={socialLink.image?.alt ? socialLink.image.alt : 'social image'}
                        />
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

export default Footer;
