import React from 'react';
import { gatedModalBox, closeIcon } from './gated-modal.module.css';
import { Backdrop, Box, Fade, IconButton, Modal } from '@mui/material';
import { Close } from '@mui/icons-material';
import GatedModalForm from './gated-modal-form';

const GatedModal = ({ modalOpen, setModalOpen, gatedModalLink }) => {
  const handleClose = () => {
    setModalOpen(false);
  };
  return (
    <Modal
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      open={modalOpen}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Fade in={modalOpen}>
        <Box className={gatedModalBox}>
          <IconButton onClick={handleClose} color="inherit" className={closeIcon} size="large">
            <Close />
          </IconButton>
          <Box sx={{ padding: '2em' }}>
            <GatedModalForm setModalOpen={setModalOpen} gatedModalLink={gatedModalLink} />
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default GatedModal;
