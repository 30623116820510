// extracted by mini-css-extract-plugin
export var black = "serializers-module--black--XtEOZ";
export var button = "serializers-module--button--auWuI buttons-module--button--Di5kN";
export var center = "serializers-module--center--BPGZs";
export var dark = "serializers-module--dark--SYXW+";
export var darkGray = "serializers-module--darkGray--unxfs";
export var left = "serializers-module--left--6dvHH";
export var link = "serializers-module--link--NkDMM";
export var right = "serializers-module--right--YbfGe";
export var secondaryButton = "serializers-module--secondaryButton--7dUNQ buttons-module--button--Di5kN buttons-module--button-outline--S9SIb";
export var ugBlue = "serializers-module--ugBlue--35p-R";
export var ugBlueGray = "serializers-module--ugBlueGray--PZCFK";
export var ugDarkGray = "serializers-module--ugDarkGray--60bLO";
export var ugGrayLight = "serializers-module--ugGrayLight--MEIRD";
export var ugGrayLighter = "serializers-module--ugGrayLighter--MPCna";
export var ugGrayLighterer = "serializers-module--ugGrayLighterer--kasPY";
export var ugGrayLightest = "serializers-module--ugGrayLightest--RmRSi";
export var ugGreen = "serializers-module--ugGreen--UFXc-";
export var ugLightBlue = "serializers-module--ugLightBlue--3ECHC";
export var ugLimeGreen = "serializers-module--ugLimeGreen--gYsDe";
export var ugYellow = "serializers-module--ugYellow--3vE-B";
export var videoContainer = "serializers-module--video-container--RSBCM";
export var white = "serializers-module--white--fMcv1";