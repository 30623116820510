import React, { Fragment } from 'react';

// ** MUI Imports
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Link } from 'gatsby';
import List from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';

const ListItem = styled(MuiListItem)(({ theme }) => ({
  width: 'auto',
  color: theme.palette.text.primary,
  '&:hover': {
    background: 'rgba(0, 168, 124, 0.3)',
    textDecoration: 'none',
  },
  '&.active, &.active:hover': {
    background: 'rgba(0, 168, 124, 0.3)',
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  color: '#fff',
  fontWeight: 400,
  fontSize: '20px',
}));

const NavLink = (props) => {
  // ** Props
  const { item, hasParent } = props;

  const Wrapper = !hasParent ? List : Fragment;

  return (
    <Wrapper {...(!hasParent ? { component: 'div', sx: { py: 2.75 } } : {})}>
      <ListItem component={Link} to={item.route?.slug?.current === undefined ? '/' : `/${item.route?.slug?.current}`}>
        <Box sx={{ gap: 2, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Text>{item.title}</Text>
          </Box>
        </Box>
      </ListItem>
    </Wrapper>
  );
};

export default NavLink;
