import { graphql, StaticQuery } from 'gatsby';
import React, { useState, createContext } from 'react';
import Layout from '../components/layout/layout';
import GatedModal from '../components/gated-modal';
import GatedModalForm from '../components/gated-modal-form';
import CookieConsent from 'react-cookie-consent';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { cn } from '../lib/helpers';
import { navBurgerIcon, navBurgerIconOpen } from '../components/layout/header.module.css';

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      preloaderAnimation
    }
  }
`;

export const SiteContext = createContext({
  gatedModalOpen: false,
  setGatedModalOpen: () => {},
  loaderDone: false,
  setLoaderDone: () => {},
});

function LayoutContainer(props) {
  const [showCookieBar, setShowCookieBar] = useState(true);
  const [gatedModalOpen, setGatedModalOpen] = useState(false);
  const [gatedModalLink, setGatedModalLink] = useState('');
  const [loaderDone, setLoaderDone] = useState(false);
  const value = {
    gatedModalOpen,
    setGatedModalOpen,
    gatedModalLink,
    setGatedModalLink,
    loaderDone,
    setLoaderDone,
  };

  const handleCloseCookie = () => {
    setShowCookieBar(false);
  };

  return (
    <StaticQuery
      query={query}
      render={(data) => {
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the studio at http://localhost:3333 and add "Site settings" data'
          );
        }
        return (
          <SiteContext.Provider value={value}>
            <Layout {...props} siteTitle={props.siteTitle} siteSubtitle={props.siteSubtitle} siteSetting={data.site} />
            <GatedModal gatedModalLink={gatedModalLink} modalOpen={gatedModalOpen} setModalOpen={setGatedModalOpen} />
            <div style={{ display: 'none' }}>
              {/*
                This form is duplicated and hidden here also to render it for netlify forms to pick it up
                it can be removed if we end up not using netlify forms later
              */}
              <GatedModalForm />
            </div>
            {loaderDone && showCookieBar && (
              <CookieConsent
                onAccept={() => {
                  initializeAndTrack(props.location);
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#000',
                  width: '80%',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  paddingRight: '5rem',
                }}
                contentStyle={{ margin: '0', padding: '2rem 4rem 2rem 2rem' }}
                location="bottom"
                buttonText="Accept"
                buttonStyle={{
                  backgroundColor: 'var(--color-ug-seagreen)',
                  color: 'var(--color-white)',
                  width: '125px',
                  padding: '.75rem 0',
                  borderRadius: '100px',
                  fontSize: '14px',
                  fontWeight: 'bold',
                }}
                cookieName="gatsby-gdpr-google-analytics"
              >
                <h4 style={{ fontSize: '18px', margin: '12px 0' }}>About Cookies on this site</h4>
                <span>
                  We use cookies to collect and analyze information on site performance and usage, and to enhance and
                  customize content and advertisements. By clicking &quot;Accept&quot; or by clicking into any content
                  on this site, you agree to allow cookies to be placed. To find out more or to change your cookie
                  setting, visit the cookies section of our privacy policy.
                </span>
                <div
                  className={cn(navBurgerIcon, navBurgerIconOpen)}
                  style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }}
                  onClick={handleCloseCookie}
                >
                  <span />
                  <span />
                  <span />
                  <span />
                </div>
              </CookieConsent>
            )}
          </SiteContext.Provider>
        );
      }}
    />
  );
}

export default LayoutContainer;
