import React from 'react';
import Header from './header';
import Footer from './footer';
import '../../styles/layout.css';
import { content, darkContent } from './layout.module.css';
import { cn } from '../../lib/helpers';

// enable for mobile debugging on a preview/staging branch shows logs in a console window in app
// import { LogsContainer } from '../../utils/logs-container';

import { createTheme, ThemeProvider } from '@mui/material';

const theme = createTheme({
  components: {
    // Name of the component ⚛️
    MuiButtonBase: {
      defaultProps: {
        // The props to apply
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
  },
});

const Layout = ({ children, siteTitle, siteSubtitle, siteSetting, dark = false }) => (
  <ThemeProvider theme={theme}>
    <Header siteTitle={siteTitle} siteSubtitle={siteSubtitle} siteSetting={siteSetting} />
    <main className={cn(content, dark ? darkContent : '')}>{children}</main>
    <Footer />
    {/*<LogsContainer />*/}
  </ThemeProvider>
);

export default Layout;
