import React, { useState, useEffect, useContext, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { graphql, Link, StaticQuery } from 'gatsby';
// import SearchForm from '../search-form';
import { GatsbyImage } from 'gatsby-plugin-image';
import lottie from 'lottie-web';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import LogoLottieJSON from '../../images/logo-with-text-json-white-3.json';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useLocation } from '@gatsbyjs/reach-router';

import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Tween, ScrollTrigger } from 'react-gsap';
import { cn } from '../../lib/helpers';
// import { handlePageMap } from '../../utils/tools';
import {
  root,
  branding,
  srOnly,
  // contactContent,
  navBurgerIcon,
  navBurgerIconOpen,
  newHamburger,
  headerMenu,
  logo,
  // mainMenu,
  headerMenuContent,
  headerMenuTitle,
  subMenuItem,
  subMenuTitle,
  headerSubMenuTitle,
  // searchMobileMenu,
  // searchBtn,
  headerScrollUp,
  headerScrollDown,
  headerTransparent,
  headerAppBar,
  socialIcons,
  // button,
} from './header.module.css';
import Loader from '../../utils/loader';
import { SiteContext } from '../../containers/layout';
import { Backdrop, Box } from '@mui/material';
import NavItems from './navItems';

let html = null;
if (typeof window !== 'undefined') {
  html = window.document.querySelector('html');
}

let animation = null;

// helper to determine if hero is in viewport when scrolling up
const isInViewport = function (elem) {
  const bounding = elem.getBoundingClientRect();
  return bounding.bottom > 0;
};

const isHeroInViewport = function () {
  let heroEl = document.getElementById('section-hero');
  const heroElInViewport = heroEl && isInViewport(heroEl);
  // console.log('heroElInViewport: ', heroElInViewport);
  heroEl = null; // clear it for garbage collection
  return heroElInViewport;
};

let scrollingUpCount = 0;
const HIDE_LOADER_DEV = (process.env.HIDE_LOADER_DEV || 'false').toLowerCase() === 'true';

const Header = ({ siteSetting }) => {
  const { preloaderAnimation } = siteSetting;
  const location = useLocation();
  const [isHome, setIsHome] = useState(location.pathname === '/');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [scrollingUp, setScrollingUp] = useState(false);
  const [scrollingOverHero, setScrollingOverHero] = useState(true);
  const [showLoader, setShowLoader] = useState(preloaderAnimation !== 'none' && !HIDE_LOADER_DEV && isHome);
  const [logoTweenFinished, setLogoTweenFinished] = useState(false);
  const { loaderDone, setLoaderDone } = useContext(SiteContext);
  const [open, setOpen] = useState([]);
  const logoRef = useRef(null);
  React.useEffect(() => {
    if (logoTweenFinished && !animation) {
      animation = lottie.loadAnimation({
        loop: false,
        autoplay: false,
        container: logoRef.current,
        animationData: LogoLottieJSON,
      });
      animation.addEventListener('complete', () => {
        animation.setDirection(-1);
      });
    }
  }, [logoTweenFinished]);
  // const [isSearching, setSearching] = useState(false);
  const handleClick = (idx, idx2) => {
    if (idx2 > 0) {
      if (open[1] === idx2) {
        setOpen([idx, 0]);
      } else {
        setOpen([idx, idx2]);
      }
    } else {
      if (open[0] === idx) {
        setOpen([0, 0]);
      } else {
        setOpen([idx, 0]);
      }
    }
  };

  const toggleDrawer = () => {
    if (drawerOpen) {
      setOpen([0, 0]);
      setDrawerOpen(false);
    } else {
      setDrawerOpen(true);
    }
  };

  // initial scroll state at top transparent over header
  const showInitialScrollState = () => {
    setScrollingUp(false);
    setScrollingOverHero(true);
  };

  const showOverHeroScrollStateHidden = () => {
    setScrollingUp(true);
    setScrollingOverHero(true);
    scrollingUpCount = 0;
  };

  const hideScrollStateScrolling = () => {
    scrollingUpCount = 0;
    setScrollingUp(true);
  };

  const showScrollStateBlackReveal = () => {
    scrollingUpCount = scrollingUpCount + 1;
    // basic delay showing the bar on scroll up by ticks
    // TODO: not ideal different tick size based on mouse or trackpad etc
    if (scrollingUpCount > 15) {
      setScrollingUp(false);
      scrollingUpCount = 0;
    }
  };

  useEffect(() => {
    // don't allow scroll during loader showing so at top when its done
    loaderDone && !drawerOpen ? (html.style.overflow = 'visible') : (html.style.overflow = 'hidden');
    // if we're not on home go ahead and set loader done
    // bc we don't want to see it until next reload
    if (preloaderAnimation === 'none' || HIDE_LOADER_DEV || !isHome) {
      setLoaderDone(true);
    }
    // show the loader if on home and loader not done yet
    setShowLoader(!HIDE_LOADER_DEV && isHome && !loaderDone);
  }, [isHome, loaderDone, drawerOpen]);

  return (
    // eslint-disable-next-line react/jsx-no-undef
    <StaticQuery
      query={graphql`
        {
          mainMenu: sanityMenu(slug: { current: { eq: "main-menu" } }) {
            title
            links {
              _key
              title
              link
              buttonLink
              route {
                ... on SanityPage {
                  slug {
                    current
                  }
                  title
                }
              }
              hidden
              embeddedMenu {
                ...EmbeddedMenu
              }
              subItems {
                _key
                title
                route {
                  slug {
                    current
                  }
                  title
                }
                subItems {
                  _key
                  title
                  route {
                    slug {
                      current
                    }
                    title
                  }
                }
              }
            }
          }
          pages: allSanityPage(filter: { slug: { current: { ne: null } } }) {
            edges {
              node {
                content: contentArray {
                  ... on SanitySectionText {
                    body {
                      children {
                        text
                      }
                    }
                  }
                  ... on SanityGlobalSection {
                    content: contentArray {
                      ... on SanitySectionText {
                        body {
                          children {
                            text
                          }
                        }
                      }
                    }
                  }
                }
                id
                slug {
                  current
                }
                title
              }
            }
          }
          site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
            socialLinks {
              ... on SanitySocialIcon {
                image {
                  ...Figure
                }
                link
              }
            }
          }
        }
      `}
      render={({ mainMenu: { links: linksArray }, pages, site: { socialLinks } }) => {
        // temporarily disabled search
        // const formattedPageEdges = pages.edges.map((page) => page?.node && handlePageMap(page.node));
        return (
          <Root className={root}>
            {showLoader && <Loader preloaderAnimation={preloaderAnimation} />}
            <ClickAwayListener onClickAway={() => (drawerOpen ? toggleDrawer() : null)}>
              <div>
                {/* 2024-02-01 we temporally disable it on mobile nav*/}
                {/* <ScrollTrigger
                  start="top top"
                  end="99999"
                  onUpdate={(self) => {
                    // console.log('onUpdate self', self);
                    const { progress, direction, getVelocity, scrubDuration } = self;
                    // console.log('onUpdate getVelocity', getVelocity());
                    // console.log('onUpdate scrubDuration', scrubDuration());
                    // console.log('onUpdate progress + direction', progress, direction);
                    if (progress === 0) {
                      showInitialScrollState();
                    } else if (isHeroInViewport()) {
                      showOverHeroScrollStateHidden();
                    } else {
                      setScrollingOverHero(false);
                      // else check scrolling and set appropriate values
                      // note we set scrolling up for first time here
                      if (direction === -1) {
                        showScrollStateBlackReveal();
                      } else {
                        hideScrollStateScrolling();
                      }
                    }
                  }}
                /> */}

                {/* Logo */}
                {((isHome && loaderDone) || !isHome) && (
                  <Tween
                    from={{ x: '-250px' }}
                    to={{
                      x: 0,
                      onComplete: () => {
                        setLogoTweenFinished(true);
                      },
                    }}
                    ease="power1.out()"
                    duration={0.2}
                    delay={0.5}
                  >
                    <Link
                      to="/"
                      onMouseOver={() => {
                        animation.setDirection(1);
                        animation.play();
                      }}
                      onClick={(event) => {
                        setDrawerOpen(false);
                        // workaround for animation getting messed up when clicking on logo from home page
                        if (location.pathname === '/') {
                          event.preventDefault();
                          window.scrollTo(0, 0);
                        }
                      }}
                      onMouseOut={() => {
                        animation.play();
                      }}
                      className={branding}
                    >
                      <div ref={logoRef} id="header-logo" className={logo} />
                      <span className={srOnly}>Go to home page</span>
                    </Link>
                  </Tween>
                )}

                <AppBar className={cn(classes.mobileNav)}>
                  <Toolbar disableGutters>
                    {((isHome && loaderDone) || !isHome) && (
                      <Tween from={{ x: '250px' }} to={{ x: 0 }} ease="power1.out()" duration={0.2} delay={0.5}>
                        <IconButton
                          className={cn(classes.hamburgerButton, newHamburger)}
                          color="inherit"
                          aria-label="menu"
                          onClick={toggleDrawer}
                          size="large"
                        >
                          <div className={cn(navBurgerIcon, drawerOpen && navBurgerIconOpen)}>
                            <span />
                            <span />
                            <span />
                            <span />
                          </div>
                        </IconButton>
                      </Tween>
                    )}

                    <Box width={50} />
                  </Toolbar>
                </AppBar>

                {/* Mobile Nav */}
                <AppBar
                  position="fixed"
                  // className={cn(classes.overlayNavMobile, scrollingUp ? headerScrollDown : headerScrollUp)}
                  className={cn(classes.overlayNavMobile, headerScrollDown)}
                />

                {/* Desktop Nav */}
                <AppBar className={cn(classes.desktopNav, loaderDone ? headerScrollDown : headerScrollUp)}>
                  <Toolbar>
                    <Box flexGrow={1} />
                    <NavItems horizontalNavItems={linksArray} />
                    <Box />
                  </Toolbar>
                </AppBar>

                <Toolbar />
                <Backdrop
                  sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}
                  onClick={() => (drawerOpen ? toggleDrawer() : null)}
                  open={drawerOpen}
                />
                <Drawer classes={{ root: classes.drawer }} variant={'persistent'} anchor="right" open={drawerOpen}>
                  <div className={classes.drawerInner}>
                    <nav className={headerMenu}>
                      <div className={headerMenuContent}>
                        <List
                          component="nav"
                          aria-labelledby="nested-list-subheader"
                          className={classes.headerMenuList}
                          disablePadding
                        >
                          {linksArray.map(({ _key, title, link, route, href, subItems }, idx) => {
                            const menuLink = link || (route?.slug?.current && `/${route.slug.current}`);
                            const updatedHref = menuLink?.slice(-1) === '/' ? menuLink.slice(0, -1) : menuLink;
                            const categoryIdx = idx + 1;
                            const categoryOpen = open[0] === categoryIdx;
                            const currentPath = location.pathname;
                            return (
                              <div key={_key}>
                                <ListItem
                                  button
                                  className={`${classes.headerMenuNavItem} ${
                                    categoryOpen || (currentPath.includes(updatedHref) && classes.headerMenuActive)
                                  } ${categoryOpen && classes.subMenuWrapActive}`}
                                  onClick={() => handleClick(categoryIdx, 0)}
                                >
                                  <>
                                    <Link to={updatedHref} onClick={toggleDrawer} className={headerMenuTitle}>
                                      {title}
                                    </Link>
                                    {subItems.length > 0 && (
                                      <ListItemSecondaryAction>
                                        <div
                                          className={cn(
                                            classes.hamburgerMenuButton,
                                            classes.hamburgerWhite,
                                            'hamburgerButton'
                                          )}
                                        />
                                      </ListItemSecondaryAction>
                                    )}
                                  </>
                                </ListItem>
                                {categoryOpen &&
                                  subItems.map((embeddedLink, idx2) => {
                                    const itemIdx = idx2 + 1;
                                    const itemOpen = open[1] === itemIdx;
                                    const subMenuLink =
                                      embeddedLink.route?.slug?.current && `/${embeddedLink.route.slug.current}`;
                                    const updatedSubHref =
                                      subMenuLink?.slice(-1) === '/' ? subMenuLink.slice(0, -1) : subMenuLink;

                                    return (
                                      <>
                                        <ListItem
                                          button
                                          key={embeddedLink._key}
                                          className={cn(
                                            classes.headerMenuNavItem,
                                            classes.subMenuWrap,
                                            itemOpen && classes.subMenuWrapActive
                                          )}
                                          onClick={() => handleClick(categoryIdx, itemIdx)}
                                        >
                                          <div className={headerMenuTitle}>
                                            <Link
                                              to={updatedSubHref}
                                              onClick={toggleDrawer}
                                              className={cn(headerSubMenuTitle, subMenuTitle)}
                                            >
                                              {embeddedLink.title}
                                            </Link>
                                            {embeddedLink.subItems.length > 0 && (
                                              <ListItemSecondaryAction>
                                                <div className={cn(classes.hamburgerMenuButton, 'hamburgerButton')} />
                                              </ListItemSecondaryAction>
                                            )}
                                          </div>
                                        </ListItem>
                                        {itemOpen &&
                                          embeddedLink.subItems.map((embeddedLinkLink) => (
                                            <ListItem
                                              button
                                              key={embeddedLinkLink._key}
                                              className={classes.headerMenuNavItem}
                                            >
                                              <div className={cn(headerMenuTitle, subMenuItem)}>
                                                <Link
                                                  onClick={toggleDrawer}
                                                  to={`/${embeddedLinkLink.route?.slug?.current}`}
                                                  className={headerMenuTitle}
                                                >
                                                  {embeddedLinkLink.title}
                                                </Link>
                                              </div>
                                            </ListItem>
                                          ))}
                                      </>
                                    );
                                  })}
                              </div>
                            );
                          })}
                        </List>
                      </div>
                    </nav>
                    <div className={socialIcons}>
                      {socialLinks.map((socialLink) => {
                        return (
                          <a key={socialLink.link} target="_blank" href={socialLink.link}>
                            <GatsbyImage
                              image={socialLink.image?.image?.asset?.gatsbyImageData}
                              alt={socialLink.image?.alt ? socialLink.image.alt : 'social image'}
                            />
                          </a>
                        );
                      })}
                    </div>
                  </div>
                </Drawer>
              </div>
            </ClickAwayListener>
          </Root>
        );
      }}
    />
  );
};

const PREFIX = 'Header';

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  left: `${PREFIX}-left`,
  right: `${PREFIX}-right`,
  contactLink: `${PREFIX}-contactLink`,
  drawer: `${PREFIX}-drawer`,
  mobileNav: `${PREFIX}-mobileNav`,
  desktopNav: `${PREFIX}-desktopNav`,
  drawerInner: `${PREFIX}-drawerInner`,
  hamburgerButton: `${PREFIX}-hamburgerButton`,
  headerMenuList: `${PREFIX}-headerMenuList`,
  headerMenuNavItem: `${PREFIX}-headerMenuNavItem`,
  megaMenuContent: `${PREFIX}-megaMenuContent`,
  subMenuTitle: `${PREFIX}-subMenuTitle`,
  subMenu: `${PREFIX}-subMenu`,
  menuTitle: `${PREFIX}-menuTitle`,
  hamburgerMenuButton: `${PREFIX}-hamburgerMenuButton`,
  hamburgerWhite: `${PREFIX}-hamburgerWhite`,
  headerMenuActive: `${PREFIX}-headerMenuActive`,
  subMenuWrap: `${PREFIX}-subMenuWrap`,
  subMenuWrapActive: `${PREFIX}-subMenuWrapActive`,
  overlayNavMobile: `${PREFIX}-overlayNavMobile`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.title}`]: {
    flexGrow: 1,
  },

  [`& .${classes.overlayNavMobile}`]: {
    display: 'block',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },

  [`& .${classes.mobileNav}`]: {
    display: 'block',
    backgroundColor: 'transparent',
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',

    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },

  [`& .${classes.desktopNav}`]: {
    display: 'none',
    background: 'linear-gradient(347deg, #000 -0.91%, rgba(0, 0, 0, 0.80) 96.15%) !important',

    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },

  [`& .${classes.left}`]: {
    display: 'flex',
    flex: 1,
    '& > a': {
      display: 'inline-block',
      fontSize: '14px',
      padding: '1rem',
      paddingBottom: 0,
      marginLeft: '-0.3125rem',
      fontWeight: 500,
      cursor: 'pointer',
      color: '#292931',
      height: '100%',
      textDecoration: 'none !important',
    },
    '& span': {
      borderBottom: '3px solid transparent',
      paddingBottom: '1rem',
      display: 'block',
      height: 'calc( 100% + 1px)',
    },
    '& > a:hover': {
      color: '#ee3224',
    },
    '& > a:hover span': {
      borderColor: '#ee3224',
    },
    '& > a:hover+.mega-content': {
      display: 'block',
    },
    '& > a.active span': {
      borderColor: '#ee3224',
    },
  },

  [`& .${classes.right}`]: {
    '& > button': {
      marginLeft: '0.25em',
      minWidth: 120,
      boxShadow: 'inset 0 0px 0 0px #ee3224',
      transition: 'box-shadow .15s ease-in-out',
      '&.light-hover:hover': {
        backgroundColor: 'unset',
        boxShadow: 'inset 0 -40px 0 -1px #ee3224',
      },
      '&.dark-hover:hover': {
        boxShadow: 'inset 0 -40px 0 -1px #ce1c0d',
      },
    },
  },

  [`& .${classes.contactLink}`]: {
    color: 'white',
  },

  [`& .${classes.drawer}`]: {
    '& > div': {
      border: 'none',
    },
    '& .MuiDrawer-paper': {
      width: '555px',
      height: '91vh',
      backgroundColor: 'var(--color-black)',
      padding: 48,

      [theme.breakpoints.down('md')]: {
        width: '93vw',
        padding: '36px 32px',
      },

      [theme.breakpoints.down('sm')]: {
        height: '100vh',
        width: '86vw',
        padding: '36px 32px',
      },
    },
  },

  [`& .${classes.drawerInner}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '84vh',
    backgroundColor: 'var(--color-black)',
    color: 'var(--color-white)',
    marginTop: 72,
    '& a': {
      color: 'var(--color-white)',
      display: 'block',
    },
    '& a:hover': {
      textDecoration: 'none',
    },
  },

  [`& .${classes.hamburgerButton}`]: {
    padding: 0, // remove default padding
    '&:hover': {
      background: 'transparent',
    },
  },

  [`& .${classes.headerMenuList}`]: {
    margin: 0,
    padding: 0,
  },

  [`& .${classes.headerMenuNavItem}`]: {
    padding: 8,
    '&:last-of-type': {
      borderBottom: 'none',
    },
    ':hover': {
      background: '#32323c',
    },
  },

  [`& .${classes.megaMenuContent}`]: {
    color: '#292931',
    padding: '2rem 2rem 1rem',
    position: 'absolute',
    backgroundColor: '#fff',
    width: '100%',
    left: 0,
    top: '3.25rem',
    borderBottom: '1px solid #f8f8f8',
    display: 'none',
    '&:hover': {
      display: 'block',
    },
    '& ul a': {
      color: '#292931',
      fontSize: '14px',
      lineHeight: '1rem',
      display: 'block',
      paddingBottom: '.5rem',
    },
    '& ul': {
      listStyleType: 'none',
      margin: 0,
      padding: 0,
    },
  },

  [`& .${classes.subMenuTitle}`]: {
    fontWeight: 700,
    pointerEvents: 'none',
    paddingBottom: '.5rem',
    textTransform: 'uppercase',
  },

  [`& .${classes.subMenu}`]: {
    textTransform: 'initial',
    paddingRight: '2rem',
    '& li a': {
      paddingBottom: 0,
      fontWeight: 400,
    },
    '& span': {
      paddingBottom: '.5rem',
    },
  },

  [`& .${classes.menuTitle}`]: {},

  [`& .${classes.hamburgerWhite}`]: {
    '&:before, &:after': {
      backgroundColor: '#FFF !important',
    },
  },

  // 2 and 20
  [`& .${classes.hamburgerMenuButton}`]: {
    position: 'relative',
    width: 20,
    height: 20,

    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      backgroundColor: '#000000',
      transition: 'transform 0.25s ease-out',
    },

    /* Vertical line */
    '&:before': {
      top: 0,
      left: '50%',
      width: 2,
      height: '100%',
      marginLeft: -1,
    },

    /* horizontal line */
    '&:after': {
      top: '50%',
      left: 0,
      width: '100%',
      height: 2,
      marginTop: -1,
    },

    '&:hover': {
      cursor: 'pointer',

      '&:before': {
        transform: 'rotate(90deg)',
      },
      '&:after': {
        transform: 'rotate(180deg)',
      },
    },
  },

  [`& .${classes.headerMenuActive}`]: {
    background: '#32323c',
    '& a': {
      color: '#FFFFFF',
    },
    '& .hamburgerButton:before, & .hamburgerButton:after': {
      backgroundColor: '#FFFFFF',
    },
    '& .hamburgerButton:before': {
      transform: 'rotate(90deg)',
    },
    '& .hamburgerButton:after': {
      transform: 'rotate(180deg)',
    },

    '&:hover': {
      background: '#292931',
    },
  },

  [`& .${classes.subMenuWrap}`]: {
    backgroundColor: '#f2f5f9',

    '&:hover': {
      '&>div>div>div': {
        '&:before, &:after': {
          backgroundColor: '#FFF !important',
        },
      },
    },
  },

  [`& .${classes.subMenuWrapActive}`]: {
    '& .hamburgerButton:before': {
      transform: 'rotate(90deg)',
    },
    '& .hamburgerButton:after': {
      transform: 'rotate(180deg)',
    },
  },
}));

export default Header;
