const React = require('react');
const LayoutContainer = require('./src/containers/layout').default;

exports.onRouteUpdate = ({ location }) => {
  // console.log('location: ', location);
  if (!window.Grnhse && location.pathname.includes('careers-list')) {
    let greenhouseScript = document.getElementById('greenhouseScript');
    if (!greenhouseScript) {
      greenhouseScript = document.createElement('script');
      greenhouseScript.id = 'greenhouseScript';
      greenhouseScript.src = `https://boards.greenhouse.io/embed/job_board/js?for=${process.env.GATSBY_BOARD_TOKEN}`;
      document.body.appendChild(greenhouseScript);
    }
  }
};

// eslint-disable-next-line react/display-name
exports.wrapPageElement = ({ element, props }) => {
  // wrap page with layout so we will not re-render the nav and trigger animations
  // every time a route changes props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <LayoutContainer {...props}>{element}</LayoutContainer>;
};
